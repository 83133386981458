<template>
  <div class="datatable_wrap" :class="{loading: loading}">
    <div class="waiting" v-if="loading">
      <SquareLoader :loading="loading" color="#05BCDA"></SquareLoader>
    </div>
    <table class="default datatable hover" v-if="data.length > 0">
      <thead>
        <th v-for="id in activeColumns" :class="[{ active: sortKey == id, filerable: columns[id]['filterable'] }, `header_${id.toLowerCase()} td_${id.toLowerCase()}`]">
          <ColumnHeader :column="columns[id]" :column-key="id" @tablefilter="setFilter" />
        </th>
      </thead>
      <tbody>
        <tr v-for="(row, index) in data" :class="{active: row.id == currentRowId}">
          <td v-for="colName in activeColumns" :class="`cell_${colName.toLowerCase()} td_${colName.toLowerCase()}`" @click="rowClick(row.slug, index)">
            <slot :name="colName" :object="row">{{ row[colName] }}</slot>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="interaction_loader">&nbsp;</div>
  </div>
</template>

<script>

import ColumnHeader from './data_table_column_header.vue'
import { SquareLoader } from 'vue-spinner/dist/vue-spinner'

export default {
  components: {ColumnHeader, SquareLoader},
  props: ['columns', 'data', 'activeColumns', 'loading', 'currentRowId'],
  mounted() {
    // this.columns.forEach( (c) => {
    //   this.sortOrders[c] = -1
    //   this.queries[c] = ''
    // })
  },
  data() {
    return {
      sortOrders: {},
      queries: {},
      sortKey: '',
      filter: {}
    }
  },
  watch: {
    'filter': {
      deep: true,
      handler(val) {
        this.$emit('filter', val)
      }
    }
  },
  computed: {
  },
  methods: {
    rowClick(id, index) {
      this.$emit('open', id, index)
    },
    setFilter(val, key) {
      this.filter[key] = val
    }
  }
}
</script>
